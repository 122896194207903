import React, { useState, useEffect } from 'react';
import { Container, Box, Paper, AppBar, Toolbar, Typography, FormControl, InputLabel, Select, MenuItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove'; // Import the minus icon
import { auth, db } from './firebase'; // Make sure your Firebase is set up
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import SimpleViewer from './SimpleViewer';
import NavigationBar from './NavigationBar';
import ColorLibrary from './ColorLibrary'; // Assuming you have a separate color library
import tintLibrary from './TintLibrary';  // Import the tint library


export default function LandingPage() {
    const [selectedColor, setSelectedColor] = useState('#ffffff');
    const [selectedFinish, setSelectedFinish] = useState('gloss');
    const [selectedTint, setSelectedTint] = useState(0); // Initial tint level
    const [selectedModel, setSelectedModel] = useState('bmw_m4'); // Initial model selection
    const [userColors, setUserColors] = useState({ gloss: [], satin: [], matte: [], premium: [] }); // User's colors by finish
    const [openPopup, setOpenPopup] = useState(false);
    const [currentFinish, setCurrentFinish] = useState('gloss'); // Which finish type is being edited in the popup
    const [selectedPopupColors, setSelectedPopupColors] = useState([]); // Colors selected in the popup
    const [removalMode, setRemovalMode] = useState(false); // Track if we're in removal mode

    const user = auth.currentUser;

    // Load user's colors and tint levels from Firestore on mount
    useEffect(() => {
        if (user) {
            const loadUserConfig = async () => {
                const userConfigRef = doc(db, 'configurations', user.uid);
                const userConfigSnap = await getDoc(userConfigRef);
                if (userConfigSnap.exists()) {
                    const userData = userConfigSnap.data();
                    setUserColors(userData.colors || { gloss: [], satin: [], matte: [], premium: [] });
                    setSelectedTint(userData.tint || 0); // Load user's saved tint level
                } else {
                    // Initialize an empty color configuration for the user
                    await setDoc(userConfigRef, { colors: { gloss: [], satin: [], matte: [], premium: [] }, tint: 0 });
                }
            };
            loadUserConfig();
        }
    }, [user]);

    // Handle opening the color popup for adding/removing colors
    const handleOpenPopup = (finishType, isRemoval = false) => {
        setCurrentFinish(finishType);
        setSelectedPopupColors(userColors[finishType]); // Load existing colors into the popup
        setRemovalMode(isRemoval); // Set removal mode based on the button clicked (Add or Remove)
        setOpenPopup(true);
    };

    // Handle saving the selected colors from the popup to the Firestore and state
    const handleSaveColors = () => {
        if (user) {
            const updatedColors = {
                ...userColors,
                [currentFinish]: selectedPopupColors, // Update the colors based on the selectedPopupColors
            };
            setUserColors(updatedColors);

            const userConfigRef = doc(db, 'configurations', user.uid);
            updateDoc(userConfigRef, { colors: updatedColors });

            setOpenPopup(false); // Close the popup
        }
    };

    // Handle color selection in the popup (for adding or removing)
    const handleColorSelectInPopup = (color) => {
        if (selectedPopupColors.includes(color)) {
            // If in removal mode, remove the color; if adding, toggle the selection
            setSelectedPopupColors(selectedPopupColors.filter((c) => c !== color));
        } else {
            // If in add mode, add the color
            if (!removalMode) {
                setSelectedPopupColors([...selectedPopupColors, color]);
            }
        }
    };

    const handleModelChange = (event) => {
        setSelectedModel(event.target.value);
    };

    const handleColorSelect = (color, finish) => {
        setSelectedColor(color);
        setSelectedFinish(finish);
    };

    // Handle tint selection
    const handleTintSelect = (opacity) => {
        setSelectedTint(opacity);
        if (user) {
            const userConfigRef = doc(db, 'configurations', user.uid);
            updateDoc(userConfigRef, { tint: opacity });
        }
    };

    return (
        <>
        <NavigationBar /> {/* Add navigation bar here */}
        <Container
            maxWidth="xl"
            sx={{
                mt: { xs: 2, md: 5 }, // Margin top adjusts based on screen size
                px: { xs: 2, md: 0 }, // Padding X for smaller screens
                fontFamily: 'Poppins, sans-serif', // Set Poppins as the main font
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 3,
                    height: { xs: 'auto', md: 'calc(100vh - 100px)' }, // Adjust height for mobile
                    overflow: 'hidden',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        borderRadius: 2,
                        p: { xs: 2, md: 3 }, // Adjust padding for mobile
                        width: { xs: '90%', md: '25%' },
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        height: { xs: 'auto', md: '90%' },
                        overflowY: 'auto',
                        fontFamily: 'Poppins, sans-serif', // Set Poppins as the font
                        border: '2px solid #00008B', // Add a blue border for highlight
                    }}
                >
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel sx={{ color: '#00008B' }}>Choose Car</InputLabel> {/* Blue label */}
                        <Select
                            value={selectedModel}
                            label="Choose Model"
                            onChange={handleModelChange}
                            sx={{
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#00008B', // Blue border
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#00008B', // Blue border on hover
                                },
                                '.MuiSvgIcon-root': {
                                    color: '#00008B', // Blue dropdown arrow
                                },
                            }}
                        >
                            <MenuItem value="bmw_m4">BMW M4</MenuItem>
                            <MenuItem value="tesla_cybertruck">Tesla Cybertruck</MenuItem>
                            <MenuItem value="rangerover">Range Rover Sport</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Display gloss, satin, matte, premium with Add and Remove buttons */}
                    {['gloss', 'satin', 'matte', 'premium'].map((finish) => (
                        <Box key={finish} sx={{ mb: 2 }}>
                            <Typography variant="h6" sx={{ textAlign: 'center', color: '#333', fontWeight: 'bold' }}>
                                {finish.toUpperCase()}
                                <IconButton onClick={() => handleOpenPopup(finish)}>
                                    <AddIcon />
                                </IconButton>
                                <IconButton onClick={() => handleOpenPopup(finish, true)}>
                                    <RemoveIcon />
                                </IconButton>
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                                {userColors[finish].map((color) => (
                                    <Box
                                        key={color}
                                        onClick={() => handleColorSelect(color, finish)}
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            borderRadius: '50%',
                                            backgroundColor: color,
                                            cursor: 'pointer',
                                        }}
                                    />
                                ))}
                            </Box>
                        </Box>
                    ))}

                    {/* Tint selection section */}
                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6" sx={{ textAlign: 'center', color: '#333', fontWeight: 'bold' }}>
                            Tint Level
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'center' }}>
                            {tintLibrary.map(({ label, opacity }) => (
                                <Button
                                    key={label}
                                    variant="contained"
                                    onClick={() => handleTintSelect(opacity)}
                                    sx={{
                                        backgroundColor: `rgba(0, 0, 0, ${opacity})`,
                                        color: '#ffffff',
                                        width: 60,
                                        height: 60,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '0.8rem',
                                        fontWeight: 'bold',
                                        boxShadow: selectedTint === opacity ? '0 0 10px 5px #00008B' : 'none',
                                    }}
                                >
                                    {label}
                                </Button>
                            ))}
                        </Box>
                    </Box>
                </Paper>

                <Paper
                    elevation={3}
                    sx={{
                        borderRadius: 2,
                        p: { xs: 2, md: 3 }, // Adjust padding for mobile
                        flexGrow: 1,
                        width: { xs: '90%', md: '75%' },
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        height: { xs: 'auto', md: '90%' }, // Adjust height for mobile
                        overflow: 'hidden',
                        border: '2px solid #00008B', // Add a blue border for highlight
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <SimpleViewer
                            selectedModel={selectedModel}
                            selectedColor={selectedColor}
                            selectedFinish={selectedFinish}
                            selectedTint={selectedTint}
                        />
                    </Box>
                </Paper>
            </Box>

            {/* Color Selection/Removal Popup */}
            <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
                <DialogTitle>
                    {removalMode ? `Remove Colors from ${currentFinish.toUpperCase()}` : `Select Colors for ${currentFinish.toUpperCase()}`}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {ColorLibrary[currentFinish].map((color) => (
                            <Grid item key={color.color}>
                                <Box
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: '50%',
                                        backgroundColor: color.color,
                                        cursor: 'pointer',
                                        border: selectedPopupColors.includes(color.color) ? '3px solid #000' : '1px solid #ccc',
                                    }}
                                    onClick={() => handleColorSelectInPopup(color.color)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSaveColors} color="primary">Save</Button>
                    <Button onClick={() => setOpenPopup(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Container>
        </>
    );
}
