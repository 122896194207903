// TryItYourself.js
import React, { useState } from 'react';
import {
  Container,
  Box,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
} from '@mui/material';
import SimpleViewer from './SimpleViewer';
import NavigationBar from './NavigationBar'; // Import the NavigationBar component
import { Link } from 'react-router-dom';

const TryItYourself = () => {
  const [selectedColor, setSelectedColor] = useState('#ffffff');
  const [selectedFinish, setSelectedFinish] = useState('gloss');
  const [selectedTint, setSelectedTint] = useState(0); // Initial tint level
  const [selectedModel, setSelectedModel] = useState('bmw_m4'); // Initial model selection

  // Limited options for demo version
  const carModels = [
    { value: 'bmw_m4', label: 'BMW M4' },
  ];

  const colors = [
    { color: '#ffffff', finish: 'gloss', label: 'White Gloss' },
    { color: '#3D3F52', finish: 'matte', label: 'Matte Grey' },
    { color: '#ff0000', finish: 'gloss', label: 'Red Gloss' },
  ];

  const tints = [
    { opacity: 0, label: 'No Tint' },
    { opacity: 0.5, label: '50% Tint' },
  ];

  const handleColorSelect = (color, finish) => {
    setSelectedColor(color);
    setSelectedFinish(finish);
  };

  const handleTintSelect = (opacity) => {
    setSelectedTint(opacity);
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  return (
    <Box>
      <NavigationBar />
            {/* Call-to-Action Section */}
            <Box sx={{ py: 5, textAlign: 'center', backgroundColor: '#0d47a1', color: '#fff', mt: 4 }}>
        <Container>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Unlock Full Customization!
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            You've experienced just a glimpse of what our full product can offer. Unlock premium models, a wider range of colors, advanced tinting options, and high-resolution 3D rendering today. Don't miss out on making your vehicle truly unique!
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ px: 5, py: 1.5, fontSize: '1.2rem', fontWeight: 'bold' }}
            component={Link}
            to="/pricing"
          >
            Get Full Access Now
          </Button>
        </Container>
      </Box>

      <Container
        maxWidth="xl"
        sx={{
          mt: { xs: 2, md: 5 },
          px: { xs: 2, md: 0 },
          fontFamily: 'Poppins, sans-serif',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
            height: { xs: 'auto', md: 'calc(100vh - 100px)' },
            overflow: 'hidden',
          }}
        >
          {/* Controls Panel */}
          <Paper
            elevation={3}
            sx={{
              borderRadius: 2,
              p: { xs: 2, md: 3 },
              width: { xs: '100%', md: '25%' },
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              height: { xs: 'auto', md: '90%' },
              overflowY: 'auto',
              fontFamily: 'Poppins, sans-serif',
              border: '2px solid #00008B',
            }}
          >
            {/* Car Model Selector */}
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel sx={{ color: '#00008B' }}>Choose Car</InputLabel>
              <Select
                value={selectedModel}
                label="Choose Car"
                onChange={handleModelChange}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#00008B',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#00008B',
                  },
                  '.MuiSvgIcon-root': {
                    color: '#00008B',
                  },
                }}
              >
                {carModels.map((model) => (
                  <MenuItem key={model.value} value={model.value}>
                    {model.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Color Selector */}
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" sx={{ color: '#00008B', mb: 1 }}>
                Choose Color
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {colors.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: 40,
                      height: 40,
                      backgroundColor: item.color,
                      borderRadius: '50%',
                      cursor: 'pointer',
                      border:
                        selectedColor === item.color && selectedFinish === item.finish
                          ? '3px solid #00008B'
                          : '1px solid #ccc',
                    }}
                    onClick={() => handleColorSelect(item.color, item.finish)}
                  />
                ))}
              </Box>
            </Box>

            {/* Tint Selector */}
            <Box>
              <Typography variant="subtitle1" sx={{ color: '#00008B', mb: 1 }}>
                Choose Tint
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {tints.map((item, index) => (
                  <Button
                    key={index}
                    variant={selectedTint === item.opacity ? 'contained' : 'outlined'}
                    color="primary"
                    size="small"
                    onClick={() => handleTintSelect(item.opacity)}
                  >
                    {item.label}
                  </Button>
                ))}
              </Box>
            </Box>
          </Paper>

          {/* Viewer */}
          <Paper
            elevation={3}
            sx={{
              borderRadius: 2,
              p: { xs: 2, md: 3 },
              flexGrow: 1,
              width: { xs: '100%', md: '75%' },
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              height: { xs: 'auto', md: '90%' },
              overflow: 'hidden',
              border: '2px solid #00008B',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
              }}
            >
              <SimpleViewer
                selectedModel={selectedModel}
                selectedColor={selectedColor}
                selectedFinish={selectedFinish}
                selectedTint={selectedTint}
              />
            </Box>
          </Paper>
        </Box>
      </Container>

      {/* Footer Section */}
      <Box sx={{ backgroundColor: '#0d47a1', color: '#fff', py: 6 }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                About Us
              </Typography>
              <Typography variant="body2">
              Reignovation is a software consulting company specializing in creating innovative, custom solutions for businesses across industries. We help companies streamline operations and elevate their customer experiences with cutting-edge technology and tools.
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Quick Links
              </Typography>
              <Typography variant="body2">
                <Link to="/features" style={{ color: '#fff', textDecoration: 'none' }}>
                  Features
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/pricing" style={{ color: '#fff', textDecoration: 'none' }}>
                  Pricing
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/contact" style={{ color: '#fff', textDecoration: 'none' }}>
                  Contact Us
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/demo" style={{ color: '#fff', textDecoration: 'none' }}>
                  Try the Demo
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Contact Us
              </Typography>
              <Typography variant="body2">
                Email: <a href="mailto:support@reignovation.com" style={{ color: '#fff' }}>support@reignovation.com</a>
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 4 }}>
            © {new Date().getFullYear()} Reignovation. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default TryItYourself;
